<template>
  <div>
    <!-- {{isEmailVerified}}{{index}} -->
    <div
      class="verify-id-form"
      v-if="
        (isEmailVerified === true && index > 0) &&
        page_type === 'paylater' &&
        (formData.payment_type !== 'now' || (idMatrix.verifyCount === 4 && formData.payment_type === 'now' ))
      "
    >
      <vx-card class="mt-4 md:mt-6 mb-4 md:mb-6">
        <div class="card-title clearfix verify-mob-pads">
          <vs-col vs-w="9">
            <h5 class="lg:text-xl md:text-xl text-lg font-normal">Verify email address</h5>
          </vs-col>
          <vs-col vs-w="3" class="text-right">
            <span class="taskDone">
              <img
                v-if="isEmailVerified === true"
                :src="checkCircle"
                alt="Done"
              />
            </span>
          </vs-col>
        </div>
      </vx-card>
    </div>
    <div v-if="idMatrix.verified === true && index == 4">
      <vx-card

        :class="
          page_type === 'paylater' && formData.payment_type !== 'now'
            ? 'mt-4 md:mt-6 verify-mobile-number'
            : 'mt-4 md:mt-6 hiddenDiv'
        "
      >
      <div class="verify-mob-pads">
        <div class="card-title mb-4">
          <strong class="text-lg lg:text-xl font-normal">Verify email address</strong>
        </div>
        <p class="text-sm md:text-base leading-tight md:leading-normal font-light">
          Please enter an email address. We’ll send you a one-time code to verify it’s yours.
        </p>
        <vs-row class="mt-0 mb-2 md:mb-6 md:mt-2 lg:w-auto justify-items-stretch md:justify-items-center md:justify-center w-full m-auto items-center md:items-start flex flex-col md:flex-row" >
          <div class="mr-0 md:mr-6 lg:mr-8 mt-6 w-full md:w-1/2 flex flex-col">
            <vs-input
                class="w-full full-input"
                v-validate="'email'"
                size=""
                v-model="formData.email"
                name="email"
                id="email"
                placeholder="Enter your email address"
              />
            <span class="text-danger text-sm mt-2 w-full" v-show="errors.has('email')">
              {{ errors.first("email") }}
            </span>
          </div>
          <div class="mt-6 text-center">
            <vs-button class="primary-btn send-icon only-border-btn" v-round size="small" @click="sendVerificationCode" :disabled="!formData.email || errors.has('email')">
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1919 0.30806C14.436 0.552138 14.436 0.947866 14.1919 1.19194L7.31694 8.06694C7.07287 8.31102 6.67714 8.31102 6.43306 8.06694C6.18898 7.82287 6.18898 7.42714 6.43306 7.18306L13.3081 0.30806C13.5521 0.0639827 13.9479 0.0639827 14.1919 0.30806Z" fill="#0f67f4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1919 0.30806C14.3617 0.477834 14.4192 0.729854 14.3399 0.956471L9.96491 13.4565C9.88026 13.6983 9.65653 13.864 9.40048 13.8745C9.14443 13.8849 8.90795 13.738 8.80387 13.5038L6.4015 8.0985L0.996167 5.69613C0.761991 5.59206 0.615077 5.35557 0.625523 5.09952C0.635969 4.84348 0.801659 4.61975 1.04353 4.53509L13.5435 0.160091C13.7702 0.0807748 14.0222 0.138287 14.1919 0.30806ZM2.94442 5.19413L7.12884 7.05387C7.27023 7.11671 7.38329 7.22977 7.44613 7.37116L9.30587 11.5556L12.7313 1.76873L2.94442 5.19413Z" fill="#0f67f4"/>
                </svg>

               {{ isEmailOtpDisabled ? "Send verification code" : "  Re-send Code" }}
            </vs-button>
          </div>
        </vs-row>
      </div>
        <div class="verify-mob-pads bg-grey-background" v-if="!isEmailOtpDisabled" >
          <vs-row vs-justify="center">
            <span class="text-sm md:text-base mt-2 md:mt-0 font-normal text-dark-blue text-center">Please enter the 6-digit code we just sent to your email address.</span>
          </vs-row>

          <vs-row class="mt-6 pt-0 md:pt-1 pb-2 otp-align" vs-justify="center">
            <v-otp-input ref="otpInput" :disabled="isEmailOtpDisabled" :class="{ 'disabled-otp': isEmailOtpDisabled, 'invalidBox': isEmailOtpInvalid }" input-classes="otp-input"
              separator="" :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete"/>
          </vs-row>

          <vs-row vs-justify="center">
            <p v-if="isEmailOtpInvalid" class="width-break text-xs leading-tight text-center font-normal mb-1 md:mb-2 text-red">Verification code invaild. Please try again.</p>
            <p v-else class="width-break text-xs leading-tight text-center font-normal mb-1 md:mb-2">Didn’t get a code? Press ‘Re-send code’ button above to get a new code.</p>
          </vs-row>

          <vs-row vs-justify="center" class="pt-5 pb-0 md:pb-4">
            <vs-button class="lg:w-10/12 md:w-10/12 w-full" v-round size="large" :disabled="!isEmailOtpComplete" @click="verifyEmail">Verify email address</vs-button>
          </vs-row>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
import OTP from "./otp.vue";

export default{
  components: {
    OTP
  },
  props: [
    "formData",
    "isEmailOtpInvalid",
    "isEmailOtpDisabled",
    "idMatrix",
    "index",
    "page_type",
    "isEmailVerified",
    "checkCircle"],
  data(){
    return {
      isEmailOtpComplete: false,
    }
  },
  methods: {
    sendVerificationCode(){
      this.$emit("sendCode")
    },
    verifyEmail(){
      this.$emit("verifyEmail")
    },
    handleOnComplete(value) {
      this.formData.email_verification_code = value;
      this.isEmailOtpComplete = true;

    },
    handleOnChange(value) {

    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
  }
}
</script>
