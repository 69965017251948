<template>
  <div>
    <vs-row class="payments-page mt-0 justify-start">
      <input type="hidden" name="transaction_token" id="transaction_token" v-model="formData.transaction_token" />
      <vs-col vs-w="8" vs-lg="8" vs-sm="12" vs-xs="12" class="mb-4 md:mb-8 lg:mb-10" style="margin: 0">
        <h3 class="font-medium text-lg md:text-xl lg:text-2xl mb-3 lg:mb-4 mt-6 lg:mt-10">{{customText.heading}}</h3>
        <p class="mt-4 text-xs leading-tight md:text-sm lg:text-base lg:leading-normal lg:mt-6"> {{customText.body}}</p>
      </vs-col>
      <vs-col vs-w="8" vs-lg="8" vs-sm="12" vs-xs="12" class="mb-base" style="margin: 0">
        <vs-card class="accordin-card my-0">
          <vs-collapse accordion>
            <vs-collapse-item class="additionalCard" id="customCollapse" ref="customCollapse" :open="additionalOpen">
              <div slot="header">
                <h3 class="text-lg lg:text-xl font-normal card-title">Payment details</h3>
              </div>
              <div class="additionalContent pt-2 px-4 sm:px-6">
                <vs-row class="add-info-row place-content-start md:grid md:gap-x-8 md:grid-cols-2">
                  <div class="add-info mb-6 lg:mb-8">
                    <label style="color: #050540" class="mb-2 text-sm md:text-base font-light text-title-primary">Amount <span class="mid-blue">*</span></label>
                    <money
                      v-validate="{
                        required:true
                      }"
                      name="amount"
                      v-model="formData.amount"
                      :class="errors.has(`amount`) ? 'border-danger' : ''"
                      class="vs-input--input"
                      v-bind="moneyMask"
                      :disabled="hideCardBlock"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('amount')">{{ errors.first("amount") }}</span>
                  </div>
                  <div class="add-info mb-6 lg:mb-8">
                    <label style="color: #050540" class="mb-2 text-sm md:text-base font-light text-title-primary">Reference <span class="mid-blue">*</span></label>
                    <vs-input
                      v-validate="{
                        required: true,
                      }"
                      name="reference"
                      maxLength="20"
                      v-model="formData.reference"
                      :class="
                        errors.has(`reference`)
                          ? 'border-danger'
                          : ''
                      "
                      :disabled="hideCardBlock"
                    />
                    <span class="text-danger text-sm" v-show="errors.has('reference')">{{ errors.first("reference") }}</span>
                  </div>
                </vs-row>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>
        <vs-card class="accordin-card mt-4 md:mt-6" v-if="inputArray.length > 0 && inputArray.some(input => input.viewableByCustomer)">
          <vs-collapse accordion >
            <vs-collapse-item class="additionalCard" id="customCollapse" ref="customCollapse" :open="additionalOpen">
              <div slot="header">
                <h3 class="text-lg lg:text-xl font-normal card-title">Additional information</h3>
              </div>
              <div class="additionalContent pt-2 px-4 sm:px-6">
                <div class="flex valiadtion-popup mb-6" v-if="errors.items.length > 0">
                  <img :src="warningIcon"  class=" warning-icon"/>
                  <p class="mb-0 warningText">Please populate all mandatory fields before proceeding.</p>
                </div>

                <div v-if="inputArray.length > 0">
                  <vs-row class="add-info-row place-content-start md:grid md:gap-x-10 md:grid-cols-2">
                    <template v-for="(customField, key) in inputArray">
                      <div
                        :key="key + 0.5"
                        class="add-info mb-6 lg:mb-8"
                        v-if="
                          customField.type !== 'file' &&
                          (customField.editableByCustomer ||
                            customField.reqToSubmitPayment ||
                            (customField.displayToCustomer &&
                              Object.keys(customField.value).length > 0))
                        "
                      >
                        <label style="color: #050540" :class=" errors.has(`${customField.label}`) ? 'text-base text-danger mb-2' : 'text-base font-light mb-2'">
                          {{ customField.label }}
                          <span v-if="customField.reqToSubmitPayment" class="mid-blue">*</span>
                        </label>

                        <vs-input
                          v-if="
                            customField.type !== 'currency' && customField.type !== 'dropdown' &&
                            (customField.editableByCustomer ||
                              customField.reqToSubmitPayment)
                          "
                          v-validate="{
                            required: customField.reqToSubmitPayment ? true : false,
                            numeric: customField.type == 'number' ? true : false,
                          }"
                          :name="`${customField.label}`"
                          :type="customField.type"
                          v-model="customField.value"
                          :class="
                            errors.has(`${customField.label}`)
                              ? 'border-danger'
                              : ''
                          "
                          :disabled="!customField.editableByCustomer || (showConfirmationForSuccess)"
                        />
                        <money
                          v-else-if="
                            customField.type === 'currency' &&
                            (customField.editableByCustomer ||
                              customField.reqToSubmitPayment)
                          "
                          v-validate="{
                            customCurrency: customField.reqToSubmitPayment
                              ? true
                              : false,
                          }"
                          :name="`${customField.label}`"
                          v-model="customField.value"
                          :class="
                            errors.has(`${customField.label}`)
                              ? 'border-danger'
                              : ''
                          "
                          class="vs-input--input"
                          v-bind="moneyMask"
                          :disabled="(showConfirmationForSuccess)"
                        />

                        <vs-select
                          v-validate="{
                            required: customField.reqToSubmitPayment ? true : false,
                          }"
                          class="m-0 mob-w"
                          :class="
                            errors.has(`${customField.label}`)
                              ? 'border-danger selectExample w-full'
                              : 'selectExample w-full'
                          "
                          v-model="customField.value"
                          :name="`${customField.label}`"
                          v-else-if="
                            customField.type === 'dropdown' &&
                            (customField.editableByCustomer ||
                              customField.reqToSubmitPayment)
                          "
                          :disabled="(showConfirmationForSuccess)"
                        >
                          <vs-select-item :key="i" :value="customField" :text="customField" v-for="(customField, i) in customField.dropdownSelectFields" />
                        </vs-select>

                        <p v-else>
                          <span v-if="customField.type === 'currency'">$ {{formatToCurrency(customField.value)}}</span>
                          <span v-else>{{ customField.value }}</span>
                        </p>
                        <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
                      </div>

                      <div
                        :key="key + 0.5"
                        class="add-info mb-6 lg:mb-8"
                        v-if="
                          customField.type == 'file' &&
                          (customField.editableByCustomer ||
                            customField.reqToSubmitPayment ||
                            (customField.displayToCustomer &&
                              Object.keys(customField.value).length > 0))
                        "
                      >
                        <label style="color: #050540" :class=" errors.has(`${customField.label}`) ? 'text-base text-danger mb-2' : 'text-base font-light mb-2'">
                          {{ customField.label }}
                          <span v-if="customField.reqToSubmitPayment" class="mid-blue">*</span>
                        </label>

                        <div class="addFile" v-if="showPreview(key)">
                          <a class="text-sm" v-if="customField.value" :href="`${baseUrl}uploads/${customField.value}`" target="_blank">{{ customField.value }}</a>
                          <a class="" v-if="customField.selectedFile">{{ customField.selectedFile.name }}</a>
                          <div class="fileAction">
                            <svg
                              class="ml-auto"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              @click="clearPreview(key, customField)"
                            >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
                              fill="#757575"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
                              fill="#757575"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
                              fill="#757575"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
                              fill="#757575"
                            />
                          </svg>
                        </div>
                        </div>
                        <div class="" v-if="!showPreview(key)">
                          <input
                            type="file"
                            class="hidden"
                            v-validate="{
                              required:
                                customField.reqToSubmitPayment &&
                                Object.keys(customField.value).length == 0
                                  ? true
                                  : false,
                            }"
                            :name="customField.label"
                            accept=".pdf"
                            :ref="`uploadImgInputRef${key}`"
                            @change="updateCurrImg($event, key, customField)"
                          />
                          <vs-button
                            v-if="customField.editableByCustomer"
                            v-round
                            class="only-border-btn mt-2"
                            size="small"
                            style="width: 126px"
                            @click="uploadImgInput(key)"
                            :disabled="(showConfirmationForSuccess)"
                          >Upload file
                          </vs-button>
                        </div>
                        <div class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">
                          {{ errors.first(`${customField.label}`) }}
                        </div>
                      </div>
                    </template>
                  </vs-row>
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>
        <vs-card class="accordin-card mt-4 md:mt-6 p-0" v-if="!hideCardBlock">
          <div class="px-4 md:px-6 pt-4 md:pt-6 pb-4">
            <h3 class="text-lg lg:text-xl font-normal card-title">Payment method</h3>
          </div>
          <div class="add-info-row place-content-start lg:mt-10 md:mt-8 mt-6 px-4 md:px-6 lg:px-12">
            <vs-row class="mb-3 flex justify-around items-center flex-wrap" v-if="showConfimationForError">
              <div style="max-width:427px;" class="my-8 payment-error-alert">
                <vs-alert class="mt-5" active="true">
                  <div class="flex">
                    <span><vs-icon icon="error_outline" size="small" color="red"></vs-icon></span>
                    <div class="ml-4">
                      <h5 class="text-base font-medium mb-2">Payment could not be processed</h5>
                      <p class="text-xs text-dark-gray">Please check your details and try again.</p>
                      <p v-if="till_failure_id" class="text-xs text-dark-gray">Transaction reference: {{ till_failure_id }}</p>
                    </div>
                  </div>
                </vs-alert>
              </div>
            </vs-row>
            <vs-row class="flex justify-around items-center flex-wrap">

              <div class="flex items-center card-holder-border mb-8 lg:mb-10 mx-0 lg:mx-6">
                <div v-for="card_option in card_options" :key="card_option._id" class="flex justify-center p-3 single-card" :class="card_status.cardType === card_option.card.slug ? 'highlightDiv' : ''">
                  <div>
                    <span class="mb-0 flex flex-col items-center">
                      <S3ImageGenerator :is-base64="false" :custom-class="''" :document="card_option.card.icon" :key="card_option.card.icon" />
                      <p class="mb-0 text-xs mt-2" v-if="!SurChargable">{{ addZeroes(card_option.surcharge) }}%</p>
                    </span>
                  </div>
                </div>
              </div>
            </vs-row>
            <vs-row>
              <vs-col class="w-full">
                <vs-row class="mb-6 lg:mb-8">
                  <vs-col vs-w="12" vs-xs="12">
                    <label class="w-full text-sm md:text-base font-light text-dark-blue block mb-2">Name on card</label>
                    <vs-input class="w-full custom-w" id="force-border" v-model="formData.name" name="name" @keyup="cardNameChange" />
                    <span class="text-danger text-sm" v-if="showNameIsRequired">Card holder name is required</span>
                  </vs-col>
                </vs-row>

                <vs-row class="mb-6 lg:mb-8">
                  <vs-col>
                    <label for="number_div" class="w-full text-sm md:text-base font-light text-dark-blue block mb-2">Card number</label>
                    <div id="number_div" style="height: 35px"></div>
                    <span class="text-danger text-sm" v-if="!isCardAllowed && !showCardNumberIsRequired">Invalid card number</span>
                    <span class="text-danger text-sm" v-if="showCardNumberIsRequired">Card number is required</span>
                  </vs-col>
                </vs-row>
                <vs-row class="flex justify-between content-start">
                  <vs-col class="ccv-exp">
                    <label class="text-sm md:text-base font-light w-full text-dark-blue block mb-2">Expiry date</label>
                    <div class="flex">
                      <the-mask class="w-full custom-w expiry_date vs-input--input" placeholder="MM" masked :mask="['##']" v-model="formData.exp_month" name="exp_month" id="exp_month" />
                      <span class="font-normal flex items-end mx-3 text-black">/</span>
                      <the-mask class="w-full custom-w expiry_date vs-input--input" placeholder="YY" masked :mask="['##']" v-model="formData.exp_year" name="exp_year" id="exp_year" @keyup.native="handleExpiryCardField" />
                    </div>
                    <span class="text-danger text-sm" v-if="showExpDateIsRequired">Card expiry date is required</span>
                    <span class="text-danger text-sm" v-if="showExpMonMoreThan12">Expiry month should be between 1 and 12</span>
                    <span class="text-danger text-sm" v-if="showExpMonIsZero">Expiry month should be between 1 and 12</span>
                    <span class="text-danger text-sm" v-if="showExpired">Expiry date is invalid</span>
                  </vs-col>

                  <vs-col class="ccv-exp last">
                    <label for="number_div" class="text-sm md:text-base font-light text-dark-blue block mb-2">CVC</label>
                    <div id="cvv_div" style="height: 35px"></div>
                    <span class="text-danger text-sm" v-if="showCvcIsRequired">CVC is required</span>
                  </vs-col>
                  <vs-col class="flex justify-end items-end ccv-img hidden lg:inline" style="width: 55px; padding-bottom: 5px">
                    <label for="" class="text-base font-light text-dark-blue block mb-2">&nbsp;</label>
                    <img :src="cardImg" alt="card" height="35px" style="margin-top: -5px" />
                  </vs-col>
                </vs-row>
              </vs-col>
            </vs-row>

            <vs-row class="mt-6 lg:mt-8 mb-6 lg:mb-2">
              <vs-col vs-w="12" vs-xs="12" class="mb-8 lg:mb-10 w-min flex items-start">
                <p class="mt-1">
                  <vs-checkbox class="w-min mx-0" name="toc_acceptance" v-model="toc_acceptance"></vs-checkbox>
                </p>

                <p class="ml-2 md:ml-3 text-xs leading-tight md:text-sm">
                  I authorise the debiting of the above payment/s to the nominated payment method, and agree to the
                  <a style="cursor: pointer" @click=" navigateToNewTab({ name: 'TermsAndConditions', params: { id: termsAndCondition._id }, query: { type: 'DASHBOARD', fullDocument: 'false' } })">
                    {{ termsAndCondition ? termsAndCondition.documentName : "Terms & Conditions" }}
                  </a>
                  and
                  <a style="cursor: pointer" @click=" navigateToNewTab({ name: 'TermsAndConditions', params: { id: privacyLegals._id }, query: { type: 'DASHBOARD', fullDocument: 'false' } })">
                    {{ privacyLegals ? privacyLegals.documentName : 'Privacy Policy' }}
                  </a>.
                </p>
              </vs-col>
            </vs-row>
            <vs-row class="mt-0 pb-0 w-full block" style="text-align: center" vs-w="12">
              <vs-col class="captcha-wrap" vs-md="8" vs-md-offset="2" >
                <vue-recaptcha :sitekey="captchaKey" :loadRecaptchaScript="true" class="w-full" size="100%" @verify="verifyCaptcha"></vue-recaptcha>
              </vs-col>
            </vs-row>
            <vs-row  class="mt-6 md:mt-8 lg:mt-10 mb-4 md:mb-6 lg:mb-8">
                <vs-col style="text-center">
                  <vs-button class="w-full" size="large" v-round @click="prepareSubmit" :disabled="disablePayNow || !captchaVerified || !toc_acceptance">
                    {{ `Pay $${addZeroes(formData.amount + getFeesFromPercentage())} now` }}
                  </vs-button>
                </vs-col>
            </vs-row>
          </div>
        </vs-card>

        <!-- Transaction status for one off and confirmation-->
        <vx-card class="mt-4 md:mt-6 transaction-confirm-card" v-if="showConfirmationForSuccess">
          <div class="card-title">
            <h3 class="text-lg lg:text-xl font-normal">Transaction confirmation</h3>
          </div>
          <div class="mx-4">
            <div class="mx-auto mb-6 md:mb-8" style="border: 1px solid #219653;border-radius: 8px;max-width: 353px;">
              <vs-alert active="true" color="success" class="trans-success" style="height: auto">
                <span class="alert-icon"><img :src="checkCircle" alt="Done" /></span>
                <div class="flex flex-col mx-4">
                  <h3 class="font-normal text-base mb-2">Payment successful</h3>
                  <p class="text-xs font-normal leading-normal">Your transaction reference number is {{ till_response_uuid }}.</p>
                </div>
              </vs-alert>
            </div>
          </div>

          <vs-row class="pb-6 lg:pb-8 send-confirm-section">
            <vs-col vs-w="12" class="text-center">
              <h4 class="text-lg md:text-xl font-normal mb-4 md:mb-6">Send confirmation</h4>
              <p class="text-base px-8 md:px-10 lg:px-24 font-light">Enter a mobile number or email address and we’ll send you a confirmation for this payment.</p>
            </vs-col>
            <div class="mt-0 mb-2 md:mb-4 justify-items-stretch md:justify-items-center md:justify-center w-full m-auto items-center md:items-start flex flex-col md:flex-row">
              <div class="ph-phone mr-0 md:mr-6 lg:mr-8 mt-6 w-full md:w-1/2 flex flex-col">
                <div class="relative">
                  <vs-input class="w-full" type="text" v-model="formData.sendConfirmText" name="sendConfirmText" placeholder="Enter email address or mobile number" />
                  <span class="text-danger text-xs md:text-sm mt-2 block leading-tight" v-show="sendConfirmTextError">{{ sendConfirmTextError }}</span>
                </div>
              </div>
              <div class="mt-6">
                <vs-button class="w-auto px-8 small only-border-btn" v-round style="width: auto" :disabled="!formData.sendConfirmText" @click="sendConfirmationForTransaction">
                  Send Me Confirmations
                </vs-button>
              </div>
            </div>
          </vs-row>
        </vx-card>
      </vs-col>

      <vs-col vs-w="3" vs-lg="4" vs-md="6" vs-sm="12" vs-xs="12" class="m-0 mb-base mob-view pl-0 md:pl-4 lg:pl-6">
        <payment-summary :paymentRequestDetail="formData" :card_status="card_status" :card_options="card_options" v-on:changeTotal="updateTotal($event)"></payment-summary>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { TheMask } from "vue-the-mask";
import RequestHeader from "./components/requestHeader";
import SuccessUrl from "./components/successUrl.vue";
import FailureUrl from "./components/failureUrl.vue";
import RadioBox from "@/views/components/radiobox";
import CurrencyInput from "@/views/components/Input";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PaymentSummary from "./components/paymentSummary.vue";
import VerifyId from "./verifyId.vue";
import LoginForm from "./loginForm.vue";
import OTP from "./otp.vue";
import TheFooter from "@/layouts/guest-components/TheFooter";
import VerifyEmail from "./verifyEmail.vue";
import { Money } from "v-money";
const cardImg = require("@/assets/images/card-pic.png");
import VueRecaptcha from "vue-recaptcha";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  components: {
    VueRecaptcha,
    RadioBox,
    CurrencyInput,
    DatePicker,
    TheMask,
    VueGoogleAutocomplete,
    RequestHeader,
    SuccessUrl,
    FailureUrl,
    PaymentSummary,
    VerifyId,
    LoginForm,
    OTP,
    TheFooter,
    VerifyEmail,
    Money,
    S3ImageGenerator,
  },
  data(){
    return{
      captchaKey: process.env.VUE_APP_CAPTCHA_KEY,
      visaIcon: require("@/assets/images/icons/visa.svg"),
      amexIcon: require("@/assets/images/icons/amex.svg"),
      unionPayIcon: require("@/assets/images/icons/unionpay.svg"),
      mastercardIcon: require("@/assets/images/icons/mastercard.svg"),
      portal: process.env.VUE_APP_NAME,
      formData: {
        type:'static',
        pageId: "",
        amount: "",
        reference: "",
        mobile: "",
        maskedMobileNumber: "",
        email: "",
        name: "",
        street_address: "",
        suburb: "",
        state: "",
        postcode: "",
        exp_month: "",
        exp_year: "",
        exp_date: "",
        transaction_token: "",
        transaction_data: "",
        txStatus: [],
      },
      customText: {},
      customField: [],
      inputArray: [],
      additionalOpen:true,
      moneyMask: {
        precision: 2,
        prefix: "$ ",
      },
      card_options: [],
      card_status: {},
      isCardAllowed: true,
      showNameIsRequired: false,
      showCardNumberIsRequired: false,
      cardImg: cardImg,
      showCvcIsRequired: false,
      showExpMonIsZero: false,
      showExpired: false,
      showExpMonMoreThan12: false,
      showExpDateIsRequired: false,
      showConfirmationForSuccess: false,
      showConfimationForError: false,
      hideCardBlock: false,
      paymentJSObject: {},
      till_public_key: "",
      toc_acceptance: false,
      termsAndCondition:[],
      captchaVerified: false,
      disablePayNow: false,
      total: "",
      footerBind: false,
      pagebranding: {},
      privacyLegals:'',
      checkCircle: require("@/assets/images/icons/check-circle.png"),
      till_response_uuid: "",
      sendConfirmTextError: '',
      paymentRequestID: '',
      till_failure_id_now : '',
      till_failure_id: '',
      requestUID: '',
      requestTemplateId: '',
    }
  },
  methods:{
    ...mapActions("payment", [
      "makeStaticPayment",
      "sendConfirmation"
    ]),
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByWithCards",
    ]),
    ...mapActions("merchantGlobalSetting", [
      "fetchBrandingSettingsByMerchantId"
    ]),
    ...mapActions("apiLog", ["storeApiLogRequest", "storeApiLogResponse"]),
    prepareSubmit() {
      this.showExpMonMoreThan12 = false;
      this.showExpMonIsZero = false;
      this.showExpired = false;
      this.showCardNumberIsRequired = false;
      this.showCvcIsRequired = false;
      this.showNameIsRequired = false;
      this.showExpDateIsRequired = false;
      this.showExpDateIsRequired = false;
      this.$validator.validateAll().then(async (result) => {
        this.disablePayNow = true;
        if (result) {
          if (this.formData.amount <= 0.00) {
            this.errors.add({
              field: "amount",
              msg: "Amount must be greater than 0.00",
            });
            this.disablePayNow = false;
            return false;
          }

          if (this.formData.name === "") {
            this.$vs.notify({
              title: "Card",
              text: "Please enter name on card.",
              color: "danger",
              iconPack: "feather",
              position: "top-right",
            });
            this.disablePayNow = false;
            return;
          }

          if (this.formData.exp_month === "" || this.formData.exp_year === "") {
            this.showExpDateIsRequired = true;
            this.disablePayNow = false;
            return;
          }

          if (parseInt(this.formData.exp_month) > 12) {
            this.disablePayNow = false;
            this.showExpMonMoreThan12 = true;
            return;
          }
          if (parseInt(this.formData.exp_month) == 0) {
            this.disablePayNow = false;
            this.showExpMonIsZero = true;
            return;
          }

          this.showExpired = this.checkExpiryDate();
          if (this.showExpired) {
            this.disablePayNow = false;
            return;
          }

          if (this.card_status.validCvv === true && this.card_status.validNumber === true) {
            let exp_date = moment().set("year", this.formData.exp_year).set("month", this.formData.exp_month);
            exp_date = moment(exp_date, "MM-YYYY");
            this.formData.exp_date = exp_date._d;

            if (this.isCardAllowed) {
              this.interceptSubmit();
            } else {
              this.disablePayNow = false;
              this.$vs.notify({
                title: "Card",
                text: "Card type not allowed, please use one of the card types indicated below",
                color: "danger",
                iconPack: "feather",
                position: "top-right",
              });
              return;
            }
          } else {
            this.$vs.notify({
              title: "Card",
              text:
                this.card_status.validCvv === false
                  ? "Please enter valid cvc."
                  : this.card_status.validNumber === false
                  ? "Please enter valid card number."
                  : "Please enter valid card.",
              color: "warning",
              iconPack: "feather",
              position: "top-right",
            });
            this.disablePayNow = false;
            return;
          }
        }
      });
    },
    async interceptSubmit() {
      let self = this
      let cardData = {
        card_holder: this.formData.name,
        month: this.formData.exp_month,
        year: this.formData.exp_year,
      };

      // FP-841: store card details sent to till
      const payload = {
        cardDetail: { ...cardData, ...this.card_status },
        provider: 'Till',
        url: 'https://secure.tillpayments.com/v1/{{TOKEN}}/tokenize/creditcard',
        templateId: this.requestTemplateId,
      }

      const apiLogRequest = await this.storeApiLogRequest(payload);

      if (apiLogRequest) {
        this.requestUID = apiLogRequest.data.data;
      }

      await this.paymentJSObject.tokenize(
        cardData,
        this.submitPaymentForm,
        function (errors) {
          self.$vs.notify({
            title: "Payment",
            text: errors[0].message,
            color: "warning",
            iconPack: "feather",
            position: "top-right",
          });

          // store response data from till
          if (this.requestUID) {
            const payload = {
              uid: this.requestUID,
              data: errors
            };
            this.storeApiLogResponse(payload);
          }
        }
      );
    },
    submitPaymentForm(token, cardData) {
      // store response data from till
      if (this.requestUID) {
        const payload = {
          uid: this.requestUID,
          data: {
            token,
            cardData
          }
        };
        this.storeApiLogResponse(payload);
      }

      this.formData.transaction_token = token;
      this.formData.transaction_data = cardData;
      this.formData.pageId = this.$route.params.pageId;
      this.$vs.loading();
      const phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");

      if (this.inputArray.length > 0) {
        let customFieldData = this.inputArray.map((item) => {
          return {
            label: item.label,
            type: item.type,
            value: item.value,
          };
        });
        this.formData.customFields = JSON.stringify(customFieldData);
      }

      this.makeStaticPayment(this.formData).then(
        (res) => {
          let response = res.data.data && res.data.data.data ? res.data.data.data : null;

          this.$vs.loading.close();
          if (response && response.success === false) {
            this.$vs.notify({
              title: "Payment",
              text: response.errors[0].errorMessage,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
            });
            this.disablePayNow = false;
            this.showConfimationForError = true;
            this.failureReason = response.errors[0].errorMessage;
            if (response.uuid) {
              this.till_failure_id_now = `TX-${response.uuid}`;
              this.till_failure_id = `TX-${response.uuid}`;
            }
          } else {
            this.$vs.notify({
              title: "Payment",
              text: "Payment has been done successfully.",
              color: "success",
              iconPack: "feather",
              position: "top-right",
            });
            this.hideCardBlock = true;
            this.showConfirmationForSuccess = true;
            this.paymentRequestID = response.paymentRequestId ? response.paymentRequestId : null;
            this.till_response_uuid = response.transactionID ? response.transactionID : null;
          }
        },
        (error) => {
          this.$vs.loading.close();
          this.disablePayNow = false;
          this.showConfimationForError = true;
          this.$vs.notify({
            title: "Payment",
            text: error.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
          });
        }
      );
    },
    async initializePaymentObject() {
      // eslint-disable-next-line no-undef
      this.paymentJSObject = new PaymentJs();
      await this.paymentJSObject.init(this.till_public_key, "number_div", "cvv_div", this.setPaymentStyle);
    },
    setPaymentStyle(payment) {
      payment.setNumberStyle({
        width: "100%",
        "border-bottom": "1px solid #bdbdbd",
        "border-right": 0,
        "border-left": 0,
        "border-top": 0,
        "box-sizing": "border-box",
        height: "30px",
        outline: 0,
        "font-size": "16px",
        color: "#828282",
      });
      payment.setCvvStyle({
        width: "100%",
        "border-bottom": "1px solid #bdbdbd",
        "border-right": 0,
        "border-left": 0,
        "border-top": 0,
        outline: 0,
        "box-sizing": "border-box",
        height: "30px",
        "font-size": "16px",
        color: "#828282",
      });
      payment.numberOn("input", this.checkCardValidity);
      payment.cvvOn("input", this.checkCvvValidity);
    },
    checkCvvValidity(data) {
      this.card_status = data;

      if (this.card_status.cvvLength == 0) {
        this.showCvcIsRequired = true;
      }
      if (this.card_status.cvvLength > 0) {
        this.showCvcIsRequired = false;
      }
    },
    checkCardValidity(data) {
      this.card_status = data;
      let checkCardAllowedStatus = this.card_options.find((card_option) => {
        return card_option.card.slug === data.cardType;
      });

      if (data.cardType) {
        if (!checkCardAllowedStatus) {
          this.isCardAllowed = false;
          this.$vs.notify({
            title: "Card",
            text: "Card type not allowed, please use one of the card types indicated below",
            color: "danger",
            iconPack: "feather",
            position: "top-right",
          });
        } else {
          this.isCardAllowed = true;
        }
      }
    },
    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, { minimumFractionDigits: 2 });
    },
    cardNameChange(event) {
      const value = event.target.value;
      this.showNameIsRequired = value.length === 0;
    },
    handleExpiryCardField(event) {
      const value = event.target.value;
      this.showExpDateIsRequired = value.length === 0;
      this.checkExpiryDate();
    },
    checkExpiryDate() {
      const fullDate = `20${this.formData.exp_year}-${this.formData.exp_month}`;

      if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
        return false;
      }
      return !moment(fullDate).isAfter(new Date());
    },

    checkNotification(notify) {
      let statusLabel = "";
      if (notify.status == "Success") {
        statusLabel = "success";
      } else if (notify.status == "Fail") {
        statusLabel = "fail";
      } else if (notify.status == "Schedule registered") {
        statusLabel = "scheduleRegistered";
      } else if (notify.status == "Schedule registration failed") {
        statusLabel = "scheduleFailed";
      } else if (notify.status == "ID verification failed") {
        statusLabel = "idFailed";
      } else if (notify.status == "Refunded") {
        statusLabel = "refund";
      }

      return statusLabel;
    },
    fetchPrivacyLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchAllLegals", "DASHBOARD")
        .then((result) => {
          this.$vs.loading.close();
          this.privacyLegals = result.data.data.privacy;
          this.termsAndCondition = result.data.data.websiteTerms;
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    navigateToNewTab(routeParams) {
      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },
    verifyCaptcha() {
      this.captchaVerified = true;
    },

    updateTotal(total) {
      this.total = total;
    },
    showPreview(key) {
      const field = this.customField && this.customField[key];
      return field && field.selectedFile;
    },
    uploadImgInput(key) {
      this.$refs[`uploadImgInputRef${key}`][0].click();
    },
    updateCurrImg(input, key, fieldRow) {
      let file = input.target.files[0];
      fieldRow.selectedFile = file;
    },
    getFeesFromPercentage() {
      let fee = 0
      let matchCardType = this.card_options.find((card_option) => {
        return card_option.card.slug === this.card_status.cardType;
      });
      if (matchCardType) {
        fee = (((this.formData.amount.$numberDecimal || this.formData.amount) / 100) * matchCardType.surcharge).toFixed(2);
      }
      return parseFloat(fee);
    },
    sendConfirmationForTransaction() {
      let confirmationData = {};
      confirmationData.paymentRequestId = this.paymentRequestID;

      if (this.formData.sendConfirmText.includes('@')) {
        confirmationData.email = this.formData.sendConfirmText;
      } else {
        confirmationData.mobile = this.formData.sendConfirmText;
      }
      confirmationData.sendConfirmText = this.formData.sendConfirmText;
      confirmationData.paymentTypeForSummary = "pay-now";
      this.$vs.loading();
      this.sendConfirmation(confirmationData).then(
        (_res) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Confirmation",
            text: "Confirmation has been sent successfully.",
            color: "success",
            iconPack: "feather",
            position: "top-right",
          });
        },
        (error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Payment",
            text: error.data.message,
            color: "warning",
            iconPack: "feather",
            position: "top-right",
          });
        }
      );
    },
    emailPattern(val) {
      const regx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^04\(?\d{8}\)?$/;
      return regx.test(val);
    },

    clearPreview(key) {
      this.customField[key].selectedFile = null;
      this.$forceUpdate();
    }
  },
  watch: {
    "formData.sendConfirmText"(val) {
      if (this.emailPattern(val) === false) {
        this.sendConfirmTextError = "Invalid email address or mobile number";
        this.disableSendConfirmButton = true;
      } else {
        this.disableSendConfirmButton = false;
        this.sendConfirmTextError = "";
      }
    },
  },
  async created() {
    this.$vs.loading();
    let pageId = this.$route.params.pageId;
    this.fetchRequestTemplateByWithCards(pageId).then(async (response) => {
      const requestTemplate = response.data.data.notification[0];
      const cards = response.data.data.paymentCards;
      this.requestTemplateId = requestTemplate.requestTemplateId;
      this.$vs.loading.close();
      this.customField =requestTemplate.customFields && requestTemplate.customFields.length > 0 && requestTemplate.customFields[0].customFieldId
          ? JSON.parse(JSON.stringify(requestTemplate.customFields))
          : [];
      this.customField.forEach((e, index) => {
        if (e.requiredToSubmit) {
          this.additionalOpen = true;
        }
        e.value = e.value ? e.value : "";
        this.inputArray.push(e);
      });
      this.till_public_key = requestTemplate.connector.publicIntegration;

      this.card_options = cards;
      this.customText = requestTemplate.customText;

      if (requestTemplate.notifications && requestTemplate.notifications.txNotification) {
        let txNotification = {};
        requestTemplate.notifications.txNotification.map((item) => {
          let status = this.checkNotification(item);
          txNotification[status] = item.forEmail;
        });
        this.formData.txStatus = txNotification;
      }

      let pageBrandingInfo = {};
      const header = requestTemplate.header;

      if (header.isDefaultHeader === true) {
        await this.fetchBrandingSettingsByMerchantId(requestTemplate.merchantId).then((result) => {
          let defaultHeader = result.data.data.companyHeader;
          pageBrandingInfo.companyAbn = defaultHeader && defaultHeader.abn ? defaultHeader.abn : "";
          pageBrandingInfo.companyAddress = defaultHeader && defaultHeader.address ? defaultHeader.address : "";
          pageBrandingInfo.companyEmail = defaultHeader && defaultHeader.companyEmail ? defaultHeader.companyEmail : "";
          pageBrandingInfo.companyName = defaultHeader && defaultHeader.companyName ? defaultHeader.companyName : "";
          pageBrandingInfo.companyPhone = defaultHeader && defaultHeader.phone ? defaultHeader.phone : "";
          pageBrandingInfo.companyWeb = defaultHeader && defaultHeader.website ? defaultHeader.website : "";
        });
      } else {
        pageBrandingInfo.companyName = header.companyName;
        pageBrandingInfo.companyAbn = header.abn;
        pageBrandingInfo.companyAddress = header.address;
        pageBrandingInfo.companyPhone = header.phone;
        pageBrandingInfo.companyWeb = header.website;
        pageBrandingInfo.companyEmail = header.companyEmail;
      }
      this.pagebranding = pageBrandingInfo;
      this.footerBind = true;
      await this.initializePaymentObject();
    }).catch((ex) => {
      this.$vs.loading.close();
      this.$vs.notify({
        title: ex.data.title,
        text: ex.data.message,
        color: "danger",
        iconPack: "feather",
        position: "top-right",
      });

      return this.$router.push({ name: "payment-error" });
    });

    this.fetchPrivacyLegals();
  },
  computed:{
    SurChargable() {
      let isSurChargable = true;
      for (const card of this.card_options) {
        if (parseFloat(card.surcharge) > 0) {
          isSurChargable = false;
        }
      }

      return isSurChargable;
    },
  }
}
</script>